/*
-- smartphones
*/
@media only screen and (min-width: 320px) and (max-width: 700px){
  /* div for pages */
.toplevelpage, .boxToplevelpage {
    margin-left: 1em;
    margin-right: 1em;
    padding: 1em;
}
  /* div holding the dropdown menu */
  .boxMenu {
    margin-left: 1.03em;
  }

}

/*
-- tablet and PC
*/
@media only screen and (min-width: 701px){
  /* div for pages */
.toplevelpage, .boxToplevelpage {
    margin-left: 20%;
    margin-right: 20%;
    padding: 2em;
  }
  /* div holding the dropdown menu */
  .boxMenu {
    margin-left: 20%;
  }
}

/*
-- shared
*/
#root {
  background: linear-gradient(
    to bottom, #c8e7f2 0%, #d6ecf4 10%, #e5f2f7 30%, #c0e1ee 30%, #c0e1ee
    /* to bottom, #bce4d8 0%, #d6ecf4 10%, #e5f2f7 30%, #a1d5d2 30%, #a1d5d2 */
  );
  width: 100%;
  height: 100%;
}

.boxMenu {
  float: left;
  z-index: 9
}

.toplevelpage, .boxToplevelpage {
    width: auto;
    height: auto;
    background-color: white;
}

h3 {
    text-align: center;
    font-weight: bold;
}

.hdIntext {
  margin-bottom: 5px;
}

.txtParagraph, .boxMargbtm {
  text-indent: 0px;
  margin-bottom: 15px;
  text-align: justify;
}

.txtNormal {
  text-indent: 0px;
  margin-bottom: 5px;
  text-align: justify;
}

.cntDiagram {
  margin-bottom: 5px;
}

/*
-- hide the id column in List of Message table on contact page, a legacy part
*/
.hidden{
  display: none;
}
